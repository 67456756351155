import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-6ed4de3393/0/cache/next-npm-14.2.5-ce63d89d89-c107b45ffe.zip/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["ClubSearchField"] */ "/app/webapp/app/components/ClubSearchField.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/webapp/app/pwa/(simple-layout)/components/SimpleLayoutPageBackButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/webapp/app/pwa/components/Image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/webapp/app/pwa/components/layout/NavBackButton.tsx");
;
import(/* webpackMode: "eager" */ "/app/webapp/assets/branding/logo.svg");
;
import(/* webpackMode: "eager" */ "/app/webapp/assets/mockup/rectangle/abstimmung.webp");
;
import(/* webpackMode: "eager" */ "/app/webapp/assets/mockup/rectangle/teilnahme.webp");
;
import(/* webpackMode: "eager" */ "/app/webapp/assets/mockup/rectangle/termine.webp");
